import React, { useState } from "react";
import * as config from "../../config";
import Excel from "../layout/excel";

export default function Header(props) {
    // State 생성
    const [inputs, setInputs] = useState({
        excelPopup: false,
        slideOn: false, // 스위치 상태 추가
    });

    const propsOpenCreationPopup = () => {
        props.openCreationPopup();
    };

    const excelPopupToggle = () => {
        setInputs({ ...inputs, excelPopup: !inputs.excelPopup });
    };
/*
    const toggleSlideButton = () => {
        setInputs({ ...inputs, slideOn: !inputs.slideOn });

        // 모든 .slideOff 클래스를 가진 요소를 선택
        document.querySelectorAll(".slideOff").forEach(el => {
            if (inputs.slideOn) {
                // 슬라이드가 켜질 때 -> 숨기기
                el.style.visibility = "visible";
            } else {
                // 슬라이드가 꺼질 때 -> 보이게 하기
                el.style.visibility = "hidden";
            }
        });
    };*/

    return (
        <div className="header">
            <span className="header-name">BNS 차량 예약 캘린더</span>
            <div className="btnReserveCar" onClick={propsOpenCreationPopup}>
                차량 예약
            </div>
            <div
                className={`btnDownloadExcel transition-all duration-300`}
                onClick={excelPopupToggle}
            >
               엑셀 다운로드
            </div>

            <Excel popup={inputs.excelPopup} excelPopupToggle={excelPopupToggle}/>
            {/* 작은 슬라이드 버튼 (스위치) */}
        {/*    <div className="slideSwitch" onClick={toggleSlideButton}>
                <div
                    className="switchToggle"
                    style={{
                        transform: inputs.slideOn ? "translateX(45px)" : "translateX(13px)",
                        transition: "transform 0.3s ease-in-out",
                    }}
                />
            </div>
             스타일 추가
            <style>{`
                .slideSwitch {
                    float: right;
                    height: 40px;
                    width: 40px;
                    line-height: 40px;
                    padding: 0px 20px;
                    margin: 11px 20px 0 0;
                    background: ${inputs.slideOn ? "#4CAF50" : "#ccc"};
                    border-radius: 10px;
                    position: relative;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
                }

                .switchToggle {
                    width: 16px;
                    height: 16px;
                    background: white;
                    border-radius: 50%;
                    position: absolute;
                    left: 2px;
                }

            `}</style>*/}
        </div>
    );
}
